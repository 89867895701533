//react
import { forwardRef, useImperativeHandle, useState } from "react";

//mui components
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
//sqlitecloud hooks
import { useCheckIsTouch } from "@custom-hooks/useCheckIsTouch";

const Card = forwardRef(
  (
    {
      id = undefined,
      customSx = undefined,
      type = "info-1",
      hoverType = undefined,
      children,
      ...props
    },
    ref
  ) => {
    //get theme
    const theme = useTheme();
    //touch device condition
    const isTouch = useCheckIsTouch();
    //handle hover event
    const [isHovered, setIsHovered] = useState(isTouch);
    const handleMouseEnter = (e) => {
      if (!isTouch) {
        e.preventDefault();
        e.stopPropagation();
        setIsHovered(true);
      }
    };
    const handleMouseLeave = (e) => {
      if (!isTouch) {
        e.preventDefault();
        e.stopPropagation();
        setIsHovered(false);
      }
    };
    //expose imperative methods to parent component
    useImperativeHandle(ref, () => ({
      resetHover: () => {
        if (!isTouch) {
          setIsHovered(false);
        }
      },
      forceHover: () => {
        if (!isTouch) {
          setIsHovered(true);
        }
      },
    }));
    //define style based on type
    let sx = {
      width: "100%",
      borderRadius: "1rem",
      padding: "1rem",
      overflow: "hidden",
    };
    //check if hover state is true and hover type is defined
    if (hoverType && isHovered) {
      type = hoverType;
    }
    switch (type) {
      case "info-0":
        sx.background = theme.palette.primary.white;
        sx.borderRadius = 0;
        break;
      case "info-1":
        sx.background = theme.palette.neutral.cream;
        sx.border = `solid ${theme.palette.secondary.main} 0.0625rem`;
        break;
      case "info-2":
        sx.background = theme.palette.neutral.pale;
        sx.border = `solid ${theme.palette.primary.main} 0.0625rem`;
        break;
      case "info-3":
        sx.background = theme.palette.neutral.cream;
        sx.borderRadius = "1rem";
        break;
      case "info-4":
        sx.background = theme.palette.primary.white;
        sx.border = `solid ${theme.palette.neutral.lightGrey} 0.0625rem`;
        sx.borderRadius = 4;
        break;
      case "warning":
        sx.background = "#fffd8d";
        sx.border = `solid #fffd8d 0.0625rem`;
        break;
      case "error":
        sx.background = theme.palette.warning.light;
        sx.border = `solid ${theme.palette.error.main} 0.0625rem`;
        break;
      default:
        sx.background = theme.palette.neutral.cream;
        sx.border = `solid ${theme.palette.primary.main} 0.0625rem`;
        break;
    }
    Object.assign(sx, customSx);
    return (
      <Grid
        item
        id={id}
        sx={sx}
        {...props}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {typeof children === "function" && <>{children(isHovered)}</>}
        {typeof children !== "function" && <>{children}</>}
      </Grid>
    );
  }
);

Card.displayName = "Card";
export default Card;
