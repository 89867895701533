//
// useGetProjectApiKey.ts
//

import { useFetchData } from "@custom-hooks/useFetchData";
import { ApiKeys } from "@data-types/api-key-types";
import { FetchHookResult } from "@data-types/generic-hook-type";

/**
 * Custom hook to fetch and manage API keys for a specific project.
 *
 * @param {string} projectId - The unique identifier of the project for which to fetch the API keys.
 * @param {number} [errorRetryCount] - Max error retry count.
 *
 * @returns {FetchHookResult<ApiKeys>} The result object with the list of API keys
 *   (or `undefined` if no api keys are available), UI state indicators, and a function to manage fetched data.
 */
export function useGetProjectApiKey(
  projectId: string,
  errorRetryCount?: number
): FetchHookResult<ApiKeys> {
  const { data, ...fetchDetails } = useFetchData<ApiKeys>(
    projectId !== "",
    `/api/projects/${projectId}/apikey`,
    "useGetProjectApiKey",
    "backend",
    {
      revalidateOnFocus: false,
      errorRetryCount,
    }
  );

  return {
    data: data?.sort((a, b) => a.name.localeCompare(b.name)),
    ...fetchDetails,
  };
}
