import { useEditData_v2 } from "@custom-hooks/edit-data";
import { useGetUserSession } from "@custom-hooks/user";
import {
  HandlingViewSubscriptionFlow,
  ViewSubscriptionSuccessResponse,
} from "@data-types/billing-types";
import { EditDataOpt } from "@data-types/generic-hook-type";
import { useGetCurrentOrganizationId } from "./useGetCurrentOrganizationId";

/**
 * Custom hook to manage the subscription view logic.
 *
 * @param {EditDataOpt<ViewSubscriptionSuccessResponse>} options - Configuration options for useEditData_v2.
 *
 * @returns {object} - An object containing the following properties:
 * @property {function(): Promise<ViewSubscriptionSuccessResponse>} viewSubscription -
 *   Function to initiate the subscription view process.
 * @property {boolean} isLoading - Indicates whether the subscription view is in process.
 */
export const useViewSubscription = (
  options: EditDataOpt<ViewSubscriptionSuccessResponse>
) => {
  const { data: session } = useGetUserSession();

  const organizationId = useGetCurrentOrganizationId();
  const email =
    organizationId &&
    session?.authorizations?.organizations[organizationId]?.ownerEmail;

  const { isLoading, isMutatingData, editData } =
    useEditData_v2<ViewSubscriptionSuccessResponse>({
      ...options,
      successCallback: (data) => {
        options.successCallback?.(data);

        const stripeRedirectUrl = data.value.redirectUrl;
        if (stripeRedirectUrl) {
          window.location.href = stripeRedirectUrl;
        } else {
          console.error("Stripe redirect URL is not available.");
        }
      },
      stopGlobalError: options?.stopGlobalError ?? true,
    });

  const viewSubscription = async () => {
    const viewSubscriptionResponse = await editData({
      method: "PATCH",
      endpoint: `${process.env.NEXT_PUBLIC_BILLING_API_URL}/portal/${email}`,
      endpointCallLocation: "viewSubscription.js",
      body: {
        returnUrl: process.env.NEXT_PUBLIC_BILLING_SUCCESS_URL,
      } as HandlingViewSubscriptionFlow,
      mutateApis: [],
    });
    return viewSubscriptionResponse;
  };

  return {
    viewSubscription,
    isLoading: isLoading || isMutatingData,
  };
};
