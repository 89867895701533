import { plans } from "@app/api/billing/lib/constants";
import { PlanFunctionality } from "@data-types/billing-types";
import { useGetUserPlan } from "./useGetUserPlan";

/**
 * Custom hook to check if a specific functionality is enabled for the current user's subscription plan.
 *
 * @param {PlanFunctionality} functionality - The functionality to check.
 *
 * @returns {Object} An object containing:
 * - `isEnabled` (boolean): Whether the specified functionality is enabled for the current plan.
 * - `value` (number | undefined): The value of the functionality if enabled, otherwise `undefined`.
 */
export const useGetFunctionalityDetails = (
  functionality: PlanFunctionality
) => {
  const plan = useGetUserPlan();

  const isEnabled =
    plan &&
    Object.keys(plans[plan]?.includedFunctionality).includes(functionality);

  return {
    isEnabled: isEnabled,
    limit: isEnabled
      ? plans[plan]?.includedFunctionality[functionality]
      : undefined,
  };
};
