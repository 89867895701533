import { useGetUserSession } from "@custom-hooks/user";
import { AvailableLanguageNames } from "@data-types/available-language-types";
import { UserAttributes } from "@data-types/user-types";
import BookIcon from "@layouts/svg-icon/book-icon.svg";
import { CodeSnippetViewer } from "@tw-components/ui/code-snippet-viewer";
import { GenericDialog } from "@tw-components/ui/dialog";
import { GenericInput } from "@tw-components/ui/input";
import {
  GenericListbox,
  ListboxLabel,
  ListboxOption,
} from "@tw-components/ui/listbox";
import { usePostHog } from "posthog-js/react";
import { useEffect, useMemo, useState } from "react";
import { codeSnippets } from "./constants";
import { useBuildConnectionString } from "./hooks/useBuildConnectionString";
import { useGetErrorMessages } from "./hooks/useGetErrorMessages";
import { replaceConnectionString } from "./utils/replaceConnectionString";

type ConnectDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  projectId?: string;
};

/**
 * Component for displaying a connection details dialog, allowing users to configure and copy a connection string.
 *
 * @property {boolean} isOpen - Indicates whether the dialog is open.
 * @property {() => void} onClose - Callback function to close the dialog.
 * @property {string} [projectId] - The ID of the selected project.
 *
 * @returns {JSX.Element} The rendered `ConnectDialog` component.
 */
export function ConnectDialog({
  isOpen,
  onClose,
  projectId,
}: ConnectDialogProps): JSX.Element {
  const { data: session } = useGetUserSession(isOpen);
  const posthog = usePostHog();

  const [defaultSnippetKey, setDefaultSnippetKey] =
    useState<AvailableLanguageNames>(AvailableLanguageNames.PYTHON);

  const { project, database, user, apiKey, connectionString } =
    useBuildConnectionString(projectId ?? "", isOpen);

  const errors = useGetErrorMessages({
    project: { ...project },
    database: { ...database },
    user: { ...user },
    apiKey: { ...apiKey },
    connectionString: connectionString.text,
  });

  useEffect(() => {
    if (projectId && isOpen) {
      project.setSelected(projectId);
    } else if (project.selected === "" && project.options.sortedKeys.length) {
      project.setSelected(project.options.sortedKeys[0]);
    }
  }, [project.options.sortedKeys, projectId]);

  useEffect(() => {
    if (session) {
      const { onboarding } = session.user
        .attributes as unknown as UserAttributes;

      if (onboarding && onboarding.selectedLanguages) {
        const validLanguages = onboarding.selectedLanguages.filter(
          (language) => {
            try {
              const parsedLanguage = language as AvailableLanguageNames;
              return Object.keys(codeSnippets).includes(parsedLanguage);
            } catch (e) {
              return false;
            }
          }
        );

        if (validLanguages.length > 0) {
          setDefaultSnippetKey(validLanguages[0] as AvailableLanguageNames);
        }
      }
    }
  }, [session]);

  const [loadingConnectionString, setLoadingConnectionString] =
    useState("Generating");
  useEffect(() => {
    if (!connectionString.showLoader) return;

    const interval = setInterval(() => {
      setLoadingConnectionString((prev) => {
        if (prev === "Generating...") {
          return "Generating";
        }
        return `${prev}.`;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [connectionString.showLoader]);

  const codeSnippetsWithConnectionString = useMemo(() => {
    return !connectionString.showLoader && connectionString.text !== ""
      ? replaceConnectionString(codeSnippets, connectionString.text)
      : codeSnippets;
  }, [connectionString]);

  return (
    <GenericDialog
      title="Connection Details"
      body={
        <div className="tw-flex tw-flex-col tw-space-y-4">
          <div className="tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2">
            {projectId && project.options.options[projectId] ? (
              <GenericInput
                sizeVar="medium"
                label="Project"
                name={"project"}
                value={project.options.options[projectId].name}
                disabled={false}
                errors={errors}
                readOnly={true}
                inputClassName="tw-pointer-events-none"
              />
            ) : (
              <GenericListbox
                style="default"
                size="medium"
                label="Project"
                name="project"
                value={project.showLoader ? null : project.selected}
                onChange={(value) => project.setSelected(value ?? "")}
                placeholder={project.showLoader && "Loading projects"}
                errors={errors}
                disabled={
                  project.showLoader || project.options.sortedKeys.length === 0
                }
                showLoader={project.showLoader}
              >
                {project.options.sortedKeys.map((projectId) => (
                  <ListboxOption key={projectId} value={projectId}>
                    <ListboxLabel>
                      {project.options.options[projectId].name}
                    </ListboxLabel>
                  </ListboxOption>
                ))}
              </GenericListbox>
            )}
            <GenericListbox
              style="default"
              size="medium"
              label="Database"
              name="database"
              value={database.showLoader ? null : database.selected}
              onChange={(value) => database.setSelected(value ?? "")}
              placeholder={database.showLoader && "Loading databases"}
              errors={errors}
              disabled={
                database.showLoader || database.options.sortedKeys.length === 0
              }
              showLoader={database.showLoader}
            >
              {database.options.sortedKeys.map((databaseName) => (
                <ListboxOption key={databaseName} value={databaseName}>
                  <ListboxLabel>{databaseName}</ListboxLabel>
                </ListboxOption>
              ))}
            </GenericListbox>
          </div>
          <div className="tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2">
            <GenericListbox
              style="default"
              size="medium"
              label="User"
              name="user"
              value={user.showLoader ? null : user.selected}
              onChange={(value) => user.setSelected(value ?? "")}
              placeholder={user.showLoader && "Loading users"}
              errors={errors}
              disabled={user.showLoader || user.options.sortedKeys.length === 0}
              showLoader={user.showLoader}
            >
              {user.options.sortedKeys.map((userName) => (
                <ListboxOption key={userName} value={userName}>
                  <ListboxLabel>{userName}</ListboxLabel>
                </ListboxOption>
              ))}
            </GenericListbox>
            <GenericListbox
              style="default"
              size="medium"
              label="API Key"
              name="apiKey"
              value={apiKey.showLoader ? null : apiKey.selected}
              onChange={(value) => apiKey.setSelected(value ?? "")}
              placeholder={apiKey.showLoader && "Loading API keys"}
              errors={errors}
              disabled={
                apiKey.showLoader || apiKey.options.sortedKeys.length === 0
              }
              showLoader={apiKey.showLoader}
            >
              {apiKey.options.sortedKeys.map((apiKeyValue) => (
                <ListboxOption key={apiKeyValue} value={apiKeyValue}>
                  <ListboxLabel>
                    {apiKey.options.options[apiKeyValue].name}
                  </ListboxLabel>
                </ListboxOption>
              ))}
            </GenericListbox>
          </div>
          <GenericInput
            type="text"
            sizeVar="medium"
            label="Connection string"
            value={
              connectionString.showLoader
                ? loadingConnectionString
                : connectionString.text
            }
            name="connectionString"
            copyMode={true}
            readOnly={true}
            onCopy={() =>
              posthog.capture("connection_string_copy", {
                project_id: project.selected,
                database: database.selected,
              })
            }
            errors={errors}
            disabled={connectionString.showLoader}
          />
          <CodeSnippetViewer
            copyButtonPosition="bottom"
            variant="multiSnippet"
            codeSnippet={codeSnippetsWithConnectionString}
            defaultSnippetKey={defaultSnippetKey}
            className="tw-h-80"
          />
        </div>
      }
      secondaryButtonProps={{
        text: "Cancel",
        onClick: onClose,
      }}
      submitButtonProps={{
        variant: "tertiary",
        label: "More quickstarts",
        icon: <BookIcon />,
        href: "https://docs.sqlitecloud.io/docs/quick-start-cdn",
      }}
      onClose={onClose}
      open={isOpen}
      size="2xl"
    />
  );
}
