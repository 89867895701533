//react components

const ModalClosingButton = ({ handleClose }) => {
  return (
    <div
      style={{
        width: "fit-content",
        position: "absolute",
        top: "1.375rem",
        right: "1.125rem",
        cursor: "pointer",
      }}
      onClick={handleClose}
    >
      <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="19"
          cy="19"
          r="18.5"
          transform="matrix(-1 0 0 1 38 0)"
          fill="white"
          stroke="#D8D8D8"
        />
        <path
          d="M23.7836 25.2898L23.7837 25.2896L23.7932 25.2997C23.9792 25.4991 24.2576 25.603 24.5376 25.603C24.809 25.603 25.0772 25.5043 25.2916 25.2898C25.7028 24.8786 25.7028 24.1928 25.2916 23.7817L14.2185 12.7086C13.8074 12.2975 13.1216 12.2975 12.7105 12.7086C12.2993 13.1197 12.2993 13.8055 12.7105 14.2167L23.7836 25.2898Z"
          fill="black"
          stroke="white"
          strokeWidth="0.8"
        />
        <path
          d="M13.4645 25.603C13.7359 25.603 14.0041 25.5043 14.2185 25.2898L25.2916 14.2167C25.7028 13.8055 25.7028 13.1197 25.2916 12.7086C24.8805 12.2975 24.1947 12.2975 23.7836 12.7086L12.7105 23.7817C12.2993 24.1928 12.2993 24.8786 12.7105 25.2898C12.925 25.5043 13.1932 25.603 13.4645 25.603Z"
          fill="black"
          stroke="white"
          strokeWidth="0.8"
        />
      </svg>
    </div>
  );
};

export default ModalClosingButton;
