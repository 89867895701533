//
// constants.ts -- Constants used to handle billing
//

import {
  AddonActionNames,
  AddonLookupKey,
  PlanFunctionality,
  PlanLookupKey,
  PlanWithDefaults,
} from "@data-types/billing-types";

export const PRO_PLAN_MONTHLY_PRICE = 29;
export const PRO_PROJECT_PRICE = 29;
export const PAST_DUE_SUBSCRIPTIONS_GRACE_PERIOD_DAYS = 15;

/**
 * Subscription plans with their configurations and associated metadata.
 *
 * The `plans` object maps each `PlanLookupKey` to a `PlanWithDefaults` object.
 *
 * Notes:
 * - The `SANDBOX` and `SCALE` does not include any default actions or add-ons.
 * - The `PRO_PLAN_MONTHLY` includes the `UpgradeToPro` action and one default add-on for creating a Pro project.
 */
export const plans: Record<PlanLookupKey, PlanWithDefaults> = {
  [PlanLookupKey.SANDBOX]: {
    lookupKey: PlanLookupKey.SANDBOX,
    actions: {
      name: PlanLookupKey.SANDBOX,
      data: {
        projectId: "", // Placeholder for the project ID
        organizationId: "", // Placeholder for the organization ID
      },
    },
    defaultAddons: [], // Free plan has no default add-ons
    name: "Sandbox",
    description: "For prototypes and hobby apps.",
    additionalInfo: "Limit of 1 Sandbox project per Workspace",
    includedFunctionality: {
      [PlanFunctionality.STORAGE_IN_GB]: 1,
    },
    functionalityDetails: {
      [PlanFunctionality.CLUSTER]: {
        summary: "Single node",
        fullDescription: "Single node cluster",
      },
      [PlanFunctionality.STORAGE_IN_GB]: {
        summary: "1 GB",
        fullDescription: "1 GB storage",
      },
      [PlanFunctionality.VCPU]: {
        summary: "0.5 vCPUs",
        fullDescription: "Up to 0.5 vCPUs",
      },
      [PlanFunctionality.RAM]: {
        summary: "500MB",
        fullDescription: "Up to 0.5 GB RAM",
      },
      [PlanFunctionality.SUPPORT]: {
        summary: "Community",
        fullDescription: "Community support",
      },
      [PlanFunctionality.COLD_START]: {
        fullDescription: "Cold starts after 3 days of inactivity",
      },
    },
  },
  [PlanLookupKey.SCALE]: {
    lookupKey: PlanLookupKey.SCALE,
    actions: {
      name: PlanLookupKey.SCALE,
      data: {
        projectId: "", // Placeholder for the project ID
        organizationId: "", // Placeholder for the organization ID
      },
    },
    defaultAddons: [],
    name: "Scale",
    description: "For large-scale apps with custom requirements.",
    functionalityDetails: {
      [PlanFunctionality.INSTANCE_SIZE]: {
        fullDescription: "Dedicated and customizable instance sizes",
      },
      [PlanFunctionality.CLUSTER]: {
        fullDescription: "Custom cluster configurations",
      },
      [PlanFunctionality.REGIONS]: {
        fullDescription: "Multiple regions",
      },
      [PlanFunctionality.SUPPORT]: {
        fullDescription: "Priority support",
      },
      [PlanFunctionality.SLA]: {
        fullDescription: "Uptime SLAs",
      },
    },
    includedFunctionality: {
      [PlanFunctionality.RETENTION_IN_HOURS]: 72,
    },
  },
  [PlanLookupKey.PRO_PLAN_MONTHLY]: {
    lookupKey: PlanLookupKey.PRO_PLAN_MONTHLY,
    actions: {
      name: PlanLookupKey.PRO_PLAN_MONTHLY,
      data: {
        projectId: "", // Placeholder for the project ID
        organizationId: "", // Placeholder for the organization ID
      },
    },
    defaultAddons: [
      {
        addon: AddonLookupKey.PRO_PROJECT_MONTHLY,
        quantity: 1, // Includes one default Pro project
        action: [
          {
            name: AddonActionNames.CREATE,
            data: null,
          },
        ],
      },
    ],
    name: "Pro",
    description: "For mission-critical workload and production apps.",
    includedFunctionality: {
      [PlanFunctionality.STORAGE_IN_GB]: 10,
      [PlanFunctionality.RETENTION_IN_HOURS]: 72,
    },
    functionalityDetails: {
      [PlanFunctionality.CLUSTER]: {
        summary: "Multi node",
        fullDescription: "Multi-node cluster",
        tooltip:
          "Multi-node clusters are fault tolerant, and can handle more traffic and concurrent users.",
      },
      [PlanFunctionality.STORAGE_IN_GB]: {
        summary: "10 GB",
        fullDescription: "10 GB storage",
      },
      [PlanFunctionality.VCPU]: {
        summary: "1 vCPU",
        fullDescription: "Up to 1 vCPU",
      },
      [PlanFunctionality.RAM]: {
        summary: "1 GB",
        fullDescription: "Up to 1 GB RAM",
      },
      [PlanFunctionality.RETENTION_IN_HOURS]: {
        summary: "3 days with PITR",
        fullDescription: "3 days of backups (PITR)",
        tooltip:
          "Point-in-time recovery allows you to recover a database to any specific moment within a retention period.",
      },
      [PlanFunctionality.SUPPORT]: {
        summary: "Priority",
        fullDescription: "Email support",
      },
      [PlanFunctionality.COLD_START]: {
        fullDescription: "No cold starts",
      },
      [PlanFunctionality.EARLY_ACCESS]: {
        fullDescription: "Early access to new features",
      },
    },
  },
};
