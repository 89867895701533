"use client";

//
// sidebar-layout.tsx
//

import * as Headless from "@headlessui/react";
import clsx from "clsx";
import React from "react";
import { NavbarItem } from "./navbar";

function OpenMenuIcon() {
  return (
    <svg data-slot="icon" viewBox="0 0 20 20" aria-hidden="true">
      <path d="M2 6.75C2 6.33579 2.33579 6 2.75 6H17.25C17.6642 6 18 6.33579 18 6.75C18 7.16421 17.6642 7.5 17.25 7.5H2.75C2.33579 7.5 2 7.16421 2 6.75ZM2 13.25C2 12.8358 2.33579 12.5 2.75 12.5H17.25C17.6642 12.5 18 12.8358 18 13.25C18 13.6642 17.6642 14 17.25 14H2.75C2.33579 14 2 13.6642 2 13.25Z" />
    </svg>
  );
}

function CloseMenuIcon() {
  return (
    <svg data-slot="icon" viewBox="0 0 20 20" aria-hidden="true">
      <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
    </svg>
  );
}

function MobileSidebar({
  open,
  close,
  children,
}: React.PropsWithChildren<{ open: boolean; close: () => void }>) {
  return (
    <Headless.Dialog open={open} onClose={close} className="lg:tw-hidden">
      <Headless.DialogBackdrop
        transition
        className="tw-fixed tw-inset-0 tw-bg-black/30 tw-transition data-[closed]:tw-opacity-0 data-[enter]:tw-duration-300 data-[leave]:tw-duration-200 data-[enter]:tw-ease-out data-[leave]:tw-ease-in"
      />
      <Headless.DialogPanel
        transition
        className="tw-fixed tw-inset-y-0 tw-w-full tw-max-w-80 tw-p-2 tw-transition tw-duration-300 tw-ease-in-out data-[closed]:tw--translate-x-full"
      >
        <div className="tw-dark:tw-bg-zinc-900 tw-dark:tw-ring-white/10 tw-flex tw-h-full tw-flex-col tw-rounded-lg tw-bg-white tw-shadow-sm tw-ring-1 tw-ring-zinc-950/5">
          {children}
        </div>
      </Headless.DialogPanel>
    </Headless.Dialog>
  );
}

/**
 * SidebarLayout component accepts `collapseSidebar` prop, a boolean that
 * controls the sidebar's appearance in desktop view. If true, only sidebar
 * icons will be shown, hiding the labels or extra content.
 *
 * SidebarLayout component accepts `showMobileSidebar` prop, a boolean that
 * controls the sidebar's appearance in mobile view.
 */
export function SidebarLayout({
  showMobileSidebar,
  setShowMobileSidebar,
  collapseSidebar,
  navbar,
  sidebar,
  children,
}: React.PropsWithChildren<{
  showMobileSidebar: boolean;
  setShowMobileSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  collapseSidebar: boolean;
  navbar: React.ReactNode;
  sidebar: React.ReactNode;
}>) {
  return (
    <div
      className={clsx(
        "tw-relative tw-isolate tw-flex tw-h-full tw-max-h-full tw-min-h-svh tw-w-full tw-overflow-hidden max-lg:tw-flex-col",
        "lg:tw-bg-interface-background-light",
        "tw-dark:lg:tw-bg-interface-background-dark"
      )}
    >
      {/* Sidebar on desktop */}
      <div
        className={clsx(
          "tw-fixed tw-inset-y-0 tw-left-0 tw-z-50 tw-hidden tw-transition-all tw-duration-300 tw-ease-in-out lg:tw-block",
          collapseSidebar
            ? "tw-w-layout-sidebar-desk-small"
            : "tw-w-layout-sidebar-desk"
        )}
      >
        {sidebar}
      </div>

      {/* Sidebar on mobile */}
      <MobileSidebar
        open={showMobileSidebar}
        close={() => setShowMobileSidebar(false)}
      >
        {sidebar}
      </MobileSidebar>

      {/* Navbar on mobile */}
      <header className="tw-hidden tw-items-center tw-px-4 lg:tw-hidden">
        <div className="tw-py-2.5">
          <NavbarItem
            onClick={() => setShowMobileSidebar(true)}
            aria-label="Open navigation"
          >
            <OpenMenuIcon />
          </NavbarItem>
        </div>
        {/* <div className="tw-min-w-0 tw-flex-1">{navbar}</div> */}
      </header>

      {/* Content */}
      <main
        className={clsx(
          "tw-h-full tw-max-h-full",
          collapseSidebar && "lg:tw-pl-layout-sidebar-desk-small",
          !collapseSidebar && "lg:tw-pl-layout-sidebar-desk",
          "tw-flex-1 tw-pb-2 lg:tw-min-w-0 lg:tw-py-3 lg:tw-pr-3",
          "tw-transition-all tw-duration-300 tw-ease-in-out"
        )}
      >
        <div
          className={clsx(
            "tw-flex tw-h-full tw-max-h-full tw-min-h-0 tw-flex-1 tw-flex-col lg:tw-rounded-2xl",
            "tw-bg-interface-card-light",
            "tw-dark:tw-bg-interface-card-dark"
          )}
        >
          <div>{navbar}</div>
          <div className="tw-flex tw-flex-1 tw-flex-col tw-overflow-y-auto tw-p-4">
            {children}
          </div>
        </div>
      </main>
    </div>
  );
}
