//
// CircleBrandMark.tsx
//

import SqliteCloudCircleBrandMarkDark from "@layouts/svg-brand/sqlitecloud-circle-brand-mark-dark.svg";
import SqliteCloudCircleBrandMarkLight from "@layouts/svg-brand/sqlitecloud-circle-brand-mark-light.svg";

export function CircleBrandMark({ size = 44 }: { size?: number }) {
  // Render component
  return (
    <>
      <SqliteCloudCircleBrandMarkLight
        width={size}
        height={size}
        className="tw-dark:tw-hidden tw-block"
      />
      <SqliteCloudCircleBrandMarkDark
        width={size}
        height={size}
        className="tw-dark:tw-block tw-hidden"
      />
    </>
  );
}
