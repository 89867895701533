//
// ErrorMessage.tsx
//

import { BackendErrorResponse } from "@data-types/backend-response-types";
import { GenericDialog } from "@tw-components/ui/dialog";
import React, { useEffect, useState } from "react";

type ErrorMessageProps = {
  message: string;
  details?: BackendErrorResponse;
};

// TODO:DAMLA:TIZ - UI
const ErrorMessage: React.FC<ErrorMessageProps> = ({ message, details }) => {
  const [open, setOpen] = useState(message ? true : false);
  useEffect(() => {
    if (message) {
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 2000);
    }
  }, [message]);
  return (
    <div className="error-message">
      <GenericDialog
        title="Error"
        description={message}
        body={
          <div className="tw-flex tw-flex-row">
            {details && <pre>{JSON.stringify(details, null, 2)}</pre>}
          </div>
        }
        open={open}
        size="xl"
        buttonLayoutType="column"
      />
    </div>
  );
};

export { ErrorMessage };
