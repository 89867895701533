//
// UserBadge.tsx
//

import { useGetUserSession } from "@custom-hooks/user";
import { getInitials } from "@lib/iso-utils";
import { Avatar } from "@tw-components/ui/avatar";

export function UserBadge() {
  // Get user session
  const {
    data: session,
    isLoading: loadingSession,
    hasData: availableSession,
  } = useGetUserSession();

  const userName = session?.user?.name ?? "";
  const userEmail = session?.user?.email ?? "";

  // Render component
  return (
    <div className="tw-flex tw-flex-row tw-items-center tw-gap-x-3">
      {availableSession && !loadingSession && (
        <>
          <Avatar initials={getInitials(userName)} className="tw-size-10" />
          <div className="tw-flex tw-flex-col tw-gap-y-1">
            <div className=" tw-text-12px-semiBold">{userName}</div>
            <div className=" tw-text-12px-regular">{userEmail}</div>
          </div>
        </>
      )}
      {loadingSession && (
        <>
          <div className="tw-dark:tw-bg-interface-gray-dark tw-size-10 tw-animate-pulse tw-rounded-full tw-bg-interface-gray-light" />
          <div className="tw-flex tw-flex-col tw-gap-y-1">
            <div className="tw-dark:tw-bg-interface-gray-dark tw-h-3 tw-w-40 tw-animate-pulse tw-rounded tw-bg-interface-gray-light tw-text-12px-semiBold"></div>
            <div className="tw-dark:tw-bg-interface-gray-dark tw-h-3 tw-w-40 tw-animate-pulse tw-rounded tw-bg-interface-gray-light tw-text-12px-semiBold"></div>
          </div>
        </>
      )}
    </div>
  );
}
