//react
//mui components
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const InputWithCustomLabel = ({
  height = { xs: "unset", modal: "2.375rem" },
  py = "0.375rem",
  label,
  subtext = undefined,
  customSxLabel = undefined,
  customSxSubtext = undefined,
  alignItems = "start",
  children,
  enableMinWidth = false,
  minWidth = "11.5625rem",
  flexGrow = 1,
}) => {
  const theme = useTheme();
  let sxLabel = {
    color: theme.palette.secondary.accent3,
  };
  let sxSubText = {
    color: theme.palette.secondary.accent3,
  };
  Object.assign(sxLabel, customSxLabel);
  Object.assign(sxSubText, customSxSubtext);
  return (
    <Grid item width={"100%"} height={height} py={py} boxSizing={"content-box"}>
      <Grid
        container
        flexDirection={{ xs: "column", modal: "row" }}
        flexWrap={"nowrap"}
        alignItems={alignItems}
        justifyContent="space-between"
        height={"100%"}
        rowGap={"0.75rem"}
        columnGap={{ xs: 0, modal: 3 }}
      >
        <Grid
          item
          pb={{ xs: 0, md: 0 }}
          height={"100%"}
          width={enableMinWidth ? "unset" : "unset"}
        >
          <Grid
            container
            height={"100%"}
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <Typography as="div" variant="14px-book" align="left" sx={sxLabel}>
              {label}
            </Typography>
            {subtext && (
              <Typography
                as="div"
                variant="12px-light"
                align="left"
                sx={sxSubText}
              >
                {subtext}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          flexGrow={flexGrow}
          width={
            enableMinWidth
              ? { xs: "100%", modal: minWidth }
              : { xs: "100%", modal: "unset" }
          }
        >
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InputWithCustomLabel;
