//react
//css library
import styled from "@emotion/styled";
//mui components
import Grid from "@mui/material/Grid";
import MuiLinearProgress from "@mui/material/LinearProgress";
import { spacing } from "@mui/system";
const LinearProgress = styled(MuiLinearProgress)(spacing);

const ModalLoader = ({ showLoader }) => {
  //render
  return (
    <>
      {showLoader && (
        <Grid item>
          <LinearProgress />
        </Grid>
      )}
    </>
  );
};

export default ModalLoader;
