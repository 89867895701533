//
// convertEnvCodeToEnvString.ts
//

import { EnvironmentInfo } from "@data-types/projects-types";

/**
 * Converts an environment code to a string with corresponding label and color.
 * @param envCode - The environment code (0 = Development, 1 = Staging, 2 = Production).
 * @param theme - The theme object for accessing color codes.
 *
 * @returns {EnvironmentInfo} An object containing color and label based on the environment code.
 */
export function convertEnvCodeToEnvString(
  envCode: number,
  theme?: any
): EnvironmentInfo {
  let env: EnvironmentInfo;

  switch (envCode) {
    case 0:
      env = {
        color: theme ? theme.palette.secondary.accent13 : "",
        label: "DEVELOPMENT",
      };
      break;
    case 1:
      env = {
        color: theme ? theme.palette.secondary.accent14 : "",
        label: "STAGING",
      };
      break;
    case 2:
      env = {
        color: theme ? theme.palette.secondary.accent1 : "",
        label: "PRODUCTION",
      };
      break;
    default:
      env = {
        color: theme ? theme.palette.secondary.accent13 : "",
        label: "DEVELOPMENT",
      };
      break;
  }

  return env;
}
