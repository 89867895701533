import * as Headless from "@headlessui/react";
import clsx from "clsx";
import React, { forwardRef } from "react";
import { Description, ErrorMessage, Field, Label } from "./fieldset";

// #region Textarea

//
// component
//

// Textarea Props
export type TextareaProps = {
  className?: string;
  resizable?: boolean;
} & Omit<Headless.TextareaProps, "as" | "className">;

export const Textarea = forwardRef(function Textarea(
  { className, resizable = true, ...props }: TextareaProps,
  ref: React.ForwardedRef<HTMLTextAreaElement>
) {
  return (
    <span
      data-slot="control"
      className={clsx([
        className,
        // Basic layout
        "tw-relative tw-block tw-w-full",
        // Background color + shadow applied to inset pseudo element, so shadow blends with border in light mode
        "before:tw-absolute before:tw-inset-px before:tw-rounded-[calc(theme(borderRadius.lg)-1px)] before:tw-bg-interface-card-light",
        // Background color is moved to control and shadow is removed in dark mode so hide `before` pseudo
        "dark:before:tw-hidden",
        // Focus ring
        "after:tw-pointer-events-none after:tw-absolute after:tw-inset-0 after:tw-rounded-lg after:tw-ring-inset after:tw-ring-transparent sm:after:focus-within:tw-ring-2 sm:after:focus-within:tw-ring-brandBlues-brandSecondary-light", // Disabled state
        // Disabled state
        "before:has-[[data-disabled]]:tw-bg-interface-card-light before:has-[[data-disabled]]:tw-shadow-none data-[disabled]:placeholder:tw-text-interface-gray-light",
        // Color transitions
        "tw-transition-colors tw-duration-200",
      ])}
    >
      <Headless.Textarea
        ref={ref}
        {...props}
        className={clsx([
          // Basic layout
          "tw-relative tw-block tw-h-auto tw-w-full tw-appearance-none tw-rounded-lg tw-p-3 sm:tw-p-3",
          // Typography
          "tw-text-16px-regular tw-text-text-title-light placeholder:tw-text-text-subTitle-light dark:tw-text-text-title-dark dark:placeholder:tw-text-text-subTitle-dark",
          "data-[disabled]:tw-text-interface-gray-light dark:data-[disabled]:tw-text-interface-gray-dark",
          "data-[disabled]:placeholder:tw-text-interface-gray-light dark:data-[disabled]:placeholder:tw-text-interface-gray-dark",
          // Border
          "tw-border tw-border-interface-divider-light data-[hover]:tw-border-text-title-light dark:tw-border-interface-divider-dark dark:data-[hover]:tw-border-text-title-dark",
          // Background color
          "tw-bg-transparent dark:tw-bg-interface-card-dark",
          // Hide default focus styles
          "tw-focus:tw-outline-none",
          // Invalid state
          "data-[invalid]:data-[hover]:tw-border-semantics-error-light data-[invalid]:tw-border-semantics-error-light data-[invalid]:dark:tw-border-semantics-error-dark data-[invalid]:data-[hover]:dark:tw-border-semantics-error-dark",
          // Disabled state
          "data-[disabled]:tw-border-interface-divider-light dark:data-[disabled]:tw-border-interface-divider-dark dark:data-[hover]:data-[disabled]:tw-border-interface-divider-dark data-[disabled]:dark:tw-bg-interface-card-dark",
          // Resizable
          resizable ? "tw-resize-y" : "tw-resize-none",
          // Color transitions
          "data-[hover]:tw-transition-colors data-[hover]:tw-duration-200",
        ])}
      />
    </span>
  );
});

// #endregion

// #region GenericTextarea

// component

export interface GenericTextareaProps extends TextareaProps {
  label?: string;
  description?: string;
  errors?: Record<string, string | undefined>;
  textareaClassName?: TextareaProps["className"];
}

export const GenericTextarea = forwardRef(function GenericTextarea(
  {
    label,
    description,
    disabled,
    errors,
    textareaClassName,
    ...props
  }: GenericTextareaProps,
  ref: React.ForwardedRef<HTMLTextAreaElement>
) {
  return (
    <Field disabled={disabled}>
      {label && <Label>{label}</Label>}
      {description && <Description>{description}</Description>}
      <Textarea
        className={textareaClassName}
        {...props}
        invalid={errors && props.name && errors[props.name] ? true : false}
        ref={ref}
      />
      {errors && props.name && errors[props.name] && (
        <ErrorMessage>{errors[props.name]}</ErrorMessage>
      )}
    </Field>
  );
});

// #endregion
