import {
  Go,
  JavaScript,
  Php,
  Python,
  React,
  ReactNative,
  Swift,
} from "@data-types/available-language-types";
import { CodeSnippet } from "@tw-components/ui/code-snippet-viewer";

export const codeSnippets: Record<string, CodeSnippet> = {
  [Go.name]: {
    label: Go.name,
    language: Go.code,
    code: `// run: go mod tidy
import (
    "fmt"
    "strings"
    sqlitecloud "github.com/sqlitecloud/sqlitecloud-go"
)

func main() {
    db, err := sqlitecloud.Connect("<connection-string>")
    result, _ := db.Select("SELECT * FROM <tablename>;")
    for r := uint64(0); r < result.GetNumberOfRows(); r++ {
        id, _ := result.GetInt64Value(r, 0)
        value, _ := result.GetStringValue(r, 1)
        fmt.Printf("\\t%d: %s\\n", id, value)
    }
}`,
  },
  [JavaScript.name]: {
    label: "Node.js",
    language: JavaScript.code,
    code: `// npm install @sqlitecloud/drivers

const { Database } = require('@sqlitecloud/drivers');
const db = new Database("<connection-string>");
const result = db.sql\`SELECT * FROM <tablename>;\`;`,
  },
  [Php.name]: {
    label: Php.name,
    language: Php.code,
    code: `// composer require sqlitecloud/sqlitecloud
<?php

require_once 'vendor/autoload.php';

use SQLiteCloud\\SQLiteCloudClient;
use SQLiteCloud\\SQLiteCloudRowset;

$sqlite = new SQLiteCloudClient();
$sqlite->connectWithString("<connection-string>");

/** @var SQLiteCloudRowset */
$rowset = $sqlite->execute('SELECT * FROM <tablename>');

$sqlite->disconnect();`,
  },
  [Python.name]: {
    label: Python.name,
    language: Python.code,
    code: `# pip install sqlitecloud

import sqlitecloud

# Open the connection to SQLite Cloud
conn = sqlitecloud.connect("<connection-string>")
cursor = conn.execute('SELECT * FROM <tablename>;')
result = cursor.fetchone()

print(result)

conn.close()`,
  },
  [React.name]: {
    label: React.name,
    language: React.code,
    code: `// npm create vite@latest sqlc-quickstart
// cd sqlc-quickstart && npm install @sqlitecloud/drivers

import { useEffect, useState } from 'react';
import { Database } from '@sqlitecloud/drivers';

const db = new Database("<connection-string>");

export default function App() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const result = await db.sql\`SELECT * FROM <tablename>;\`;
      setData(result);
    };

    getData();
  }, []);

  return (
    <div>
      {/* render logic */}
    </div>
  );
}`,
  },
  [ReactNative.name]: {
    label: ReactNative.name,
    language: ReactNative.code,
    code: `/*
  npx create-expo-app sqlc-quickstart && cd sqlc-quickstart
  npm install @sqlitecloud/drivers react-native-tcp-socket react-native-quick-base64
*/

import { useEffect, useState } from 'react';
import { Database } from '@sqlitecloud/drivers';

const db = new Database("<connection-string>");

export default function App() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const result = await db.sql\`SELECT * FROM <tablename>;\`;
      setData(result);
    };

    getData();
  }, []);

  return (
    <div>
      {/* render logic */}
    </div>
  );
}`,
  },
  [Swift.name]: {
    label: Swift.name,
    language: Swift.code,
    code: `/*
To install, add to Package.swift:

let package = Package(
  ...,
  dependencies: [
    ...,
    .package(url: "https://github.com/sqlitecloud/swift.git", from: "0.2.1")
  ],
  ...
)
*/

import SQLiteCloud

let configuration: SQLiteCloudConfig? = SQLiteCloudConfig(connectionString: "<connection-string>")
let sqliteCloud: SQLiteCloud = SQLiteCloud(config: configuration!)

do {
    try await sqliteCloud.connect()
    debugPrint("connected")

    let sqlQuery: String = "USE DATABASE chinook.sqlite; SELECT albums.AlbumId as id, albums.Title as title, artists.name as artist FROM albums INNER JOIN artists WHERE artists.ArtistId = albums.ArtistId LIMIT 20;"
    let result: SQLiteCloudResult = try await sqliteCloud.execute(query: sqlQuery)

    try await sqliteCloud.disconnect()
    return result.stringValue!
} catch {
    return "Connection error"
}`,
  },
};
