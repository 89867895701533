import { useGetUserSession } from "@custom-hooks/user";
import { FetchApiOptions, fetchApiRoute } from "@lib/client-side";
import { Alerts, MessageType } from "@tw-components/ui/alerts";
import { Button } from "@tw-components/ui/button";
import { GenericDialog } from "@tw-components/ui/dialog";
import { GenericTextarea } from "@tw-components/ui/textarea";
import { Form, Formik } from "formik";
import * as Yup from "yup";

type ContactDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function ContactDialog({
  isOpen,
  onClose,
}: ContactDialogProps): JSX.Element {
  const { data: session } = useGetUserSession();

  const validationSchema = Yup.object().shape({
    message: Yup.string().required(),
  });

  return (
    <GenericDialog
      onClose={onClose}
      open={isOpen}
      title="Contact us"
      body={
        <Formik
          initialValues={{ message: "" }}
          validationSchema={validationSchema}
          validateOnMount={true}
          onSubmit={async (values, { resetForm, setSubmitting, setStatus }) => {
            try {
              setSubmitting(true);

              if (session) {
                const name = session.user.name;
                const email = session.user.email;
                const subject = `Message from ${name} | SQLite Cloud Dashboard - Billing`;
                const html = `
                  <h2>Message received from: </h2>
                  <ul>
                    <li> Name: ${name}</li>
                    <li> Email: ${email}</li> 
                  </ul>
                  <h2>User Message</h2>
                  <div>${values.message}</div>
                `;

                const opt: FetchApiOptions = {
                  method: "POST",
                  endpoint: "/api/sendMail",
                  endpointCallLocation: "Sending mail",
                  body: { subject, html, type: "billing" },
                };

                await fetchApiRoute(opt);
                resetForm();

                setStatus({
                  type: MessageType.Success,
                  title: "Message sent successfully!",
                  description:
                    "Thank you for reaching out. We'll get back to you as soon as possible.",
                });
              }
            } catch (error) {
              setStatus({
                type: MessageType.Error,
                title: "Message sending failed",
                description:
                  "An error occurred while sending your message. Please try again.",
              });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            isValid,
            isSubmitting,
            status,
            setStatus,
          }) => (
            <Form className="tw-flex tw-flex-col tw-gap-3">
              <GenericTextarea
                label="Questions, concerns or feedback"
                resizable={false}
                value={values.message}
                rows={6}
                name="message"
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  handleChange(e);
                  setStatus(undefined);
                }}
                onBlur={handleBlur}
                disabled={isSubmitting}
              />

              {status && <Alerts messages={[status]} />}

              <div className="tw-mt-3 tw-flex tw-flex-col tw-items-center">
                <Button
                  size="medium"
                  variant="primary"
                  label="Send"
                  type="submit"
                  className="tw-w-24"
                  disabled={!isValid}
                  showLoader={isSubmitting}
                />
              </div>
            </Form>
          )}
        </Formik>
      }
      size="lg"
    />
  );
}
