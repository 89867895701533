import { useEditData_v2 } from "@custom-hooks/edit-data";
import { useGetUserSession } from "@custom-hooks/user";
import {
  CreateSubscriptionSuccessResponse,
  HandlingCreateSubscriptionFlow,
  PlanLookupKey,
} from "@data-types/billing-types";
import { EditDataOpt } from "@data-types/generic-hook-type";
import { usePostHog } from "posthog-js/react";
import { useCallback } from "react";
import { useGetCurrentOrganizationId } from "./useGetCurrentOrganizationId";

/**
 * Custom hook to manage the subscription creation logic.
 *
 * @param {EditDataOpt<CreateSubscriptionSuccessResponse>} options - Configuration options for useEditData_v2.
 *
 * @returns {object} - An object containing the following properties:
 * @property {function(PlanLookupKey): Promise<CreateSubscriptionSuccessResponse>} createSubscription -
 *   Function to initiate the subscription creation process for a specified plan.
 * @property {boolean} isLoading - Indicates whether the subscription creation is in process.
 */
export const useCreateSubscription = (
  options: EditDataOpt<CreateSubscriptionSuccessResponse>
) => {
  const posthog = usePostHog();
  const { data: session, hasData: availableSession } = useGetUserSession();

  const organizationId = useGetCurrentOrganizationId();

  const projects = availableSession
    ? Object.entries(session?.authorizations?.projects ?? [])
    : [];

  const {
    email = "",
    userId = "",
    projectId = "",
  } = {
    userId: session?.userId,
    email:
      organizationId &&
      session?.authorizations?.organizations[organizationId]?.ownerEmail,
    projectId: projects.length ? projects[0][0] : undefined,
  };

  const { isLoading, isMutatingData, editData } =
    useEditData_v2<CreateSubscriptionSuccessResponse>({
      ...options,
      successCallback: (data) => {
        options.successCallback?.(data);

        const stripeRedirectUrl = data.value.redirectUrl;
        if (stripeRedirectUrl) {
          window.location.href = stripeRedirectUrl;
        } else {
          console.error("Stripe redirect URL is not available.");
        }
      },
      stopGlobalError: options?.stopGlobalError ?? true,
    });

  const createSubscription = useCallback(
    async (plan: PlanLookupKey) => {
      posthog.capture("subscribe:notify_pro");

      const createSessionResponse = await editData({
        method: "POST",
        endpoint: `/api/billing/subscriptions/${email}`,
        endpointCallLocation: "useCreateSubscription.js",
        body: {
          plan,
          successUrl: process.env.NEXT_PUBLIC_BILLING_SUCCESS_URL,
          cancelUrl: process.env.NEXT_PUBLIC_BILLING_CANCEL_URL,
          organizationId,
          email,
          userId,
          projectId,
        } as HandlingCreateSubscriptionFlow,
        mutateApis: [],
      });

      return createSessionResponse;
    },
    [email, organizationId, userId, editData]
  );

  return {
    createSubscription,
    isLoading: isLoading || isMutatingData,
  };
};
