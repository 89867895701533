//react
//mui components
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

const YesNoModalButtons = ({ disabled, disabledYes, callback, yesAction }) => {
  //render
  return (
    <Grid
      container
      flexDirection={"row"}
      justifyContent={"center"}
      columnGap={2}
      rowGap={2}
    >
      <Button onClick={callback} disabled={disabled} variant="modal-secondary">
        No
      </Button>
      <Button
        onClick={yesAction}
        disabled={disabled || disabledYes}
        color="primary"
        autoFocus
        variant="modal-primary"
      >
        Yes
      </Button>
    </Grid>
  );
};
export default YesNoModalButtons;
