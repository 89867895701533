import { useGetCurrentOrganizationId } from "@custom-hooks/billing";
import { useEditData_v2 } from "@custom-hooks/edit-data";
import { useGetUserSession } from "@custom-hooks/user";
import {
  HandlingUpdateSubscriptionFlow,
  UpdateSubscriptionSuccessResponse,
} from "@data-types/billing-types";
import { EditDataOpt } from "@data-types/generic-hook-type";
import { useCallback } from "react";

/**
 * Custom hook to manage the subscription update logic.
 *
 * @param {EditDataOpt<UpdateSubscriptionSuccessResponse>} options - Configuration options for useEditData_v2.
 *
 * @returns {object} - An object containing the following properties:
 * @property {function(AddonItem<AddonLookupKey>[]): Promise<UpdateSubscriptionSuccessResponse>} updateSubscription -
 *   Function to initiate the subscription update process for a specified add-on.
 * @property {boolean} isLoading - Indicates whether the subscription update is in process.
 */
export const useUpdateSubscription = (
  options: EditDataOpt<UpdateSubscriptionSuccessResponse>
) => {
  const { data: session, hasData: availableSession } = useGetUserSession();

  const organizationId = useGetCurrentOrganizationId();

  const { email = "", userId = "" } = availableSession
    ? {
        userId: session?.userId,
        email:
          organizationId &&
          session?.authorizations?.organizations[organizationId]?.ownerEmail,
      }
    : {};

  const { isLoading, isMutatingData, editData } =
    useEditData_v2<UpdateSubscriptionSuccessResponse>({
      ...options,
      stopGlobalError: options?.stopGlobalError ?? true,
    });

  const updateSubscription = useCallback(
    async ({
      newAddons,
    }: Pick<HandlingUpdateSubscriptionFlow, "newAddons">) => {
      const updateSubscriptionResponse = await editData({
        method: "PATCH",
        endpoint: `/api/billing/subscriptions/${email}`,
        endpointCallLocation: "useUpdateSubscription.js",
        body: {
          organizationId,
          email,
          userId,
          newAddons,
          successUrl: process.env.NEXT_PUBLIC_BILLING_SUCCESS_URL,
          cancelUrl: process.env.NEXT_PUBLIC_BILLING_CANCEL_URL,
        } as HandlingUpdateSubscriptionFlow,
        mutateApis: [
          ["/api/projects", "useGetUserProjects"],
          ["/api/projects", "useGetProjects"],
        ],
      });
      return updateSubscriptionResponse;
    },
    [email, organizationId, userId, editData]
  );

  return {
    updateSubscription,
    isLoading: isLoading || isMutatingData,
  };
};
