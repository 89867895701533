//
// billing-types.ts
//

import { Environment } from "@data-types/projects-types";
import { Region } from "@data-types/regions-types";
import { Stripe } from "stripe";

/**
 * Enum for plan functionalities.
 */
export enum PlanFunctionality {
  CLUSTER = "Cluster",
  STORAGE_IN_GB = "Storage",
  VCPU = "vCPUs",
  RAM = "RAM",
  RETENTION_IN_HOURS = "Backups",
  SUPPORT = "Support",
  COLD_START = "Cold Start",
  EARLY_ACCESS = "Early Access",
  SLA = "SLA",
  REGIONS = "Regions",
  INSTANCE_SIZE = "Instance Size",
}

/**
 * Enum for plan lookup keys.
 * These keys represent different subscription plans available in the system.
 */
export enum PlanLookupKey {
  SANDBOX = "sandbox",
  SCALE = "scale",
  PRO_PLAN_MONTHLY = "pro_plan_monthly",
}

/**
 * Enum for addon lookup keys.
 * These keys represent additional features or services that can be added to a subscription.
 */
export enum AddonLookupKey {
  PRO_PROJECT_MONTHLY = "pro_project_monthly",
}

/**
 * Enum representing the possible actions that can be performed on addons.
 *
 * - `CREATE`: Indicates the creation of a new addon or resource.
 * - `DELETE`: Indicates the deletion of an existing addon or resource.
 *
 * ## Usage
 * This enum is used to define the set of valid actions that can be associated
 * with a specific addon type. Each action corresponds to a unique operation
 * and is mapped to its respective data structure in the `AddonActionMap`.
 */
export enum AddonActionNames {
  CREATE = "create",
  DELETE = "delete",
  UNSUBSCRIBE = "unsubscribe",
}

/**
 * Defines the allowed add-ons for each plan.
 */
export type AllowedDefaultAddonsPerPlan = {
  [PlanLookupKey.SANDBOX]: never;
  [PlanLookupKey.SCALE]: never;
  [PlanLookupKey.PRO_PLAN_MONTHLY]: AddonLookupKey.PRO_PROJECT_MONTHLY;
};

/**
 * Represents the mapping between plan lookup keys and their associated actions.
 *
 * Each plan has a specific action that needs to be triggered when it is selected,
 * along with any required data to perform the action.
 */
export type PlanActionMap = {
  [PlanLookupKey.SANDBOX]: {
    name: PlanLookupKey.SANDBOX;
    data: {
      organizationId: string;
      projectId: string;
    };
  };
  [PlanLookupKey.SCALE]: {
    name: PlanLookupKey.SCALE;
    data: {
      organizationId: string;
      projectId: string;
    };
  };
  [PlanLookupKey.PRO_PLAN_MONTHLY]: {
    name: PlanLookupKey.PRO_PLAN_MONTHLY;
    data: {
      organizationId: string;
      projectId: string;
    };
  };
};

/**
 * Defines the mapping of addon lookup keys to their respective actions and data structures.
 *
 * This type specifies the valid actions for each addon type, along with the expected
 * data structure for each action. Each addon lookup key maps to an object where the keys
 * are action names (from `AddonActionNames`) and the values define the data schema
 * for that specific action.
 *
 * ## Structure
 * - `CREATE`: Represents the creation of a new addon or resource.
 *   - `data`: Contains details necessary for creating the addon, such as:
 *     - `name`: The name of the resource.
 *     - `region`: The region ID where the resource will be created.
 *     - `env`: The environment (e.g., development, production).
 *     - `description`: (Optional) Additional details about the resource.
 *   - The `data` field can be `null` if no additional details are required.
 *
 * - `DELETE`: Represents the deletion of an existing addon or resource.
 *   - `data`: Contains details necessary for deleting the addon, such as:
 *     - `projectId`: The unique identifier of the project to be deleted.
 *
 * ## Example
 * ```typescript
 * const action: AddonActionMap[AddonLookupKey.PRO_PROJECT_MONTHLY][AddonActionNames.CREATE] = {
 *   data: {
 *     name: "MyProject",
 *     region: "us-west-1",
 *     env: Environment.PRODUCTION,
 *     description: "A production project",
 *   },
 * };
 * ```
 */
export type AddonActionMap = {
  [AddonLookupKey.PRO_PROJECT_MONTHLY]: {
    [AddonActionNames.CREATE]: {
      name: AddonActionNames.CREATE; // Include the action name
      data: {
        name: string;
        region: Region["region_id"];
        env: Environment;
        description?: string;
      } | null;
    };
    [AddonActionNames.DELETE]: {
      name: AddonActionNames.DELETE; // Include the action name
      data: {
        projectId: string;
      };
    };
    [AddonActionNames.UNSUBSCRIBE]: {
      name: AddonActionNames.UNSUBSCRIBE; // Include the action name
      data: {
        projectId: string;
        organizationId: string;
      };
    };
  };
};

/**
 * Extracts the possible action types for a specific addon, including the action name.
 *
 * This type maps a specific addon lookup key to its associated actions. Each action
 * contains a `name` field indicating the type of action (e.g., "create", "delete")
 * and a `data` object containing the necessary parameters for the action.
 *
 * ## Example
 * For `AddonLookupKey.PRO_PROJECT_MONTHLY`, the possible actions are:
 * - `CREATE`: Contains data such as `name`, `region`, `env`, and optional fields like `description`.
 * - `DELETE`: Contains data with a `projectId` identifying the project to be deleted.
 *
 * ## Usage
 * Use this type to enforce strict typing when dealing with actions for specific addons,
 * ensuring that each action includes both its `name` and the correct structure for its `data` object.
 */
export type AddonAction<K extends AddonLookupKey> =
  AddonActionMap[K][keyof AddonActionMap[K]];

/**
 * Represents an addon item with a dynamic action type based on the addon lookup key.
 *
 * An `AddonItem` encapsulates the data needed to describe an addon operation within
 * the subscription system. Each addon operation includes:
 * - A lookup key (`addon`) identifying the specific addon.
 * - A `quantity` indicating how many units of the addon are being requested (must not be zero).
 * - An array of `action` objects detailing the operations to perform, where each action includes:
 *   - `name`: The type of operation (e.g., "create", "delete").
 *   - `data`: The parameters required for the operation, specific to the action name.
 *
 * ## Example
 * For an addon of type `PRO_PROJECT_MONTHLY`:
 * ```typescript
 * const addonItem: AddonItem<AddonLookupKey.PRO_PROJECT_MONTHLY> = {
 *   addon: AddonLookupKey.PRO_PROJECT_MONTHLY,
 *   quantity: 1,
 *   action: [
 *     {
 *       name: AddonActionNames.CREATE,
 *       data: {
 *         name: "Project Alpha",
 *         region: "us-east-1",
 *         env: 1,
 *         description: "First project setup",
 *       },
 *     },
 *   ],
 * };
 * ```
 *
 * ## Validations
 * - `quantity` must be a non-zero value. This indicates the number of units being added or removed.
 * - The `action` array must align with the addon key (`addon`) and include valid action names
 *   and data structures as defined in the `AddonActionMap`.
 *
 * ## Purpose
 * This type ensures strong typing and clear semantics when managing addon operations, allowing for
 * precise integration between frontend logic and backend APIs.
 */
export type AddonItem<K extends AddonLookupKey = AddonLookupKey> = {
  addon: K;
  quantity: number; // Non-zero quantity validated at runtime
  action: Array<AddonAction<K>>; // Array of actions tied strictly to the addon key
};

/**
 * Represents a subscription plan, its associated actions, default add-ons and UI details.
 *
 * - `lookupKey`: The unique identifier for the plan.
 * - `actions`: Defines actions to execute when the plan is activated.
 * - `defaultAddons`: Optional add-ons included by default, each with:
 *   - `addon`: The add-on identifier.
 *   - `quantity`: The number of add-ons included.
 *   - `action`: The actions associated with the add-on.
 * - `name` and `description`: Plan's display name and summary.
 * - `functionality`: Key features of the plan represented as key-value pairs,
 *      where the key is the functionality and the value is the numeric amount.
 * - `functionalityDetails`: Key features and their details, categorized by `PlanFunctionality`.
 * - `additionalInfo`: Optional supplementary information.
 */
export type PlanWithDefaults<K extends PlanLookupKey = PlanLookupKey> = {
  lookupKey: K;
  actions: PlanActionMap[K];
  defaultAddons: Array<AddonItem<AllowedDefaultAddonsPerPlan[K]>> | []; // Restrict addons to allowed keys for the plan
  name: string;
  description: string;
  additionalInfo?: string;
  includedFunctionality: Partial<Record<PlanFunctionality, number>>; // TODO:DAMLA:TIZ - adapt to object received from stripe
  functionalityDetails: Partial<
    Record<
      PlanFunctionality,
      { summary?: string; fullDescription: string; tooltip?: string }
    >
  >; // TODO:DAMLA:TIZ - adapt to object received from stripe
};

/**
 * Represents the base data structure for managing a subscription flow for an organization.
 *
 * - `email`: The email address of the user initiating the subscription process.
 * - `organizationId`: (Optional) The unique identifier of the organization associated with the subscription.
 * - `userId`: (Optional) The unique identifier of the user performing the action.
 * - `successUrl`: (Optional) The URL to redirect the user to after successfully completing the subscription process.
 * - `cancelUrl`: (Optional) The URL to redirect the user to if the subscription process is canceled or fails.
 * - `returnUrl`: (Optional) The URL to return to after completing the subscription flow.
 */
export interface HandlingSubscriptionFlow {
  email: string;
  organizationId?: string;
  userId?: string;
  successUrl?: string;
  cancelUrl?: string;
  returnUrl?: string;
}

/**
 * Extends `HandlingSubscriptionFlow` for creating a subscription flow.
 *
 * This structure is used when creating a new subscription for an organization, requiring all
 * relevant details such as the organization ID, user ID, and plan details.
 *
 * - `successUrl`: The URL to redirect the user to after successfully completing the subscription creation process.
 * - `cancelUrl`: The URL to redirect the user to if the subscription creation process is canceled or fails.
 * - `plan`: The lookup key identifying the subscription plan to be created.
 * - `projectId`: The unique identifier of the project associated with the subscription.
 * - `returnUrl`: This field is not applicable in the creation flow and is excluded.
 */
export interface HandlingCreateSubscriptionFlow
  extends Omit<HandlingSubscriptionFlow, "returnUrl"> {
  successUrl: string;
  organizationId: string;
  userId: string;
  cancelUrl: string;
  plan: PlanLookupKey;
  projectId: string;
}

/**
 * Extends `HandlingSubscriptionFlow` for updating a subscription flow.
 *
 * This structure is used when updating an existing subscription, requiring the organization ID, user ID,
 * and the add-ons to be added. Cancelation is not applicable for update flows.
 *
 * - `successUrl`: The URL to redirect the user to after successfully completing the subscription update process.
 * - `cancelUrl`: Must not be provided in update flows as cancelation is not applicable.
 * - `newAddons`: An array of `AddonItem` objects representing additional features or services to add to the subscription.
 * - `returnUrl`: This field is not applicable in the update flow and is excluded.
 */
export interface HandlingUpdateSubscriptionFlow
  extends Omit<
    HandlingSubscriptionFlow,
    "organizationId" | "userId" | "successUrl" | "cancelUrl"
  > {
  successUrl: string;
  cancelUrl: string;
  organizationId: string;
  userId: string;
  newAddons: AddonItem[];
}

/**
 * Extends `HandlingSubscriptionFlow` for viewing a subscription flow.
 *
 * This structure is used when displaying subscription details or confirmation pages. The `returnUrl`
 * field becomes mandatory to define the redirection after completing the viewing process.
 *
 * - `returnUrl`: The URL to return to after completing the subscription view process.
 * - Other fields (`organizationId`, `userId`, `successUrl`, `cancelUrl`) are not applicable for viewing flows.
 */
export interface HandlingViewSubscriptionFlow
  extends Omit<HandlingSubscriptionFlow, "returnUrl"> {
  returnUrl: string;
}

/**
 * Represents the data structure required to handle a subscription cancellation flow.
 *
 * This interface extends the base `HandlingSubscriptionFlow` but excludes certain optional fields
 * (`organizationId`, `userId`, `successUrl`, `cancelUrl`, and `projectId`) and redefines them as mandatory.
 *
 * ## Fields
 * - `successUrl` (string): The URL to redirect the user to after successfully canceling the subscription.
 * - `cancelUrl` (string): The URL to redirect the user to if the subscription cancellation is aborted or fails.
 * - `organizationId` (string): The unique identifier of the organization associated with the subscription to be canceled.
 * - `userId` (string): The unique identifier of the user initiating the cancellation process.
 * - `projectId` (string): The unique identifier of the project to be removed after subscription is being canceled.
 *
 * ## Usage
 * This interface ensures all required parameters for the cancellation flow
 * are provided and is primarily used in subscription cancellation API routes.
 */
export interface HandlingCancelSubscriptionFlow
  extends Omit<
    HandlingSubscriptionFlow,
    "organizationId" | "userId" | "successUrl" | "cancelUrl" | "projectId"
  > {
  successUrl: string;
  cancelUrl: string;
  organizationId: string;
  userId: string;
  projectId: string;
}

/**
 * Represents the successful response of a subscription creation process.
 *
 * This interface defines the structure of the response returned when a subscription
 * is successfully created and a Stripe Checkout session is generated.
 *
 * ## Properties:
 * - `stripeSession`: The Stripe Checkout session object that includes detailed
 *   information about the created session, such as its ID, status, and URLs.
 * - `redirectUrl`: A URL to redirect the user to complete the payment process
 *   in the Stripe Checkout interface.
 *
 */
export interface CreateSubscriptionSuccessResponse {
  stripeSession: Stripe.Response<Stripe.Checkout.Session>;
  redirectUrl: Stripe.Checkout.Session["url"];
}

/**
 * Represents the response from a successful subscription update operation.
 *
 * - `status`: Indicates the current state of the subscription update process.
 *   - `"completed"`: The subscription upgrade was successfully completed with a successful payment.
 *   - `"pending"`: The subscription update process is incomplete, and an invoice needs to be paid.
 *     In this state, the `redirectUrl` will point to the Stripe-hosted invoice page for payment.
 * - `redirectUrl`: The URL to redirect the user based on the status:
 *   - If `status` is `"completed"`, this could be a confirmation page or the application's subscription management page.
 *   - If `status` is `"pending"`, this will be the Stripe-hosted invoice page for the user to complete the payment.
 */
export interface UpdateSubscriptionSuccessResponse {
  status: "completed" | "pending";
  redirectUrl: string;
}

/**
 * Represents the response returned upon successful deletion of a subscription.
 *
 * ## Fields
 * - `redirectUrl` (string): The URL to redirect the user to after the subscription has been successfully canceled.
 *
 * ## Usage
 * This interface is used to define the structure of the success response for API routes
 * handling subscription cancellations. It provides a redirection path for the user
 * to confirm the success of the operation or guide them to the next action.
 */
export interface CancelSubscriptionSuccessResponse {
  redirectUrl: string;
}

/**
 * Represents the response for successfully handling a view subscription flow.
 *
 * - `redirectUrl`: The URL to which the user should be redirected after
 *   successfully completing the view subscription process.
 *
 */
export interface ViewSubscriptionSuccessResponse {
  redirectUrl: string;
}

/**
 * Defines a line item used in Stripe Checkout sessions.
 *
 * - `price`: The ID of the price object in Stripe.
 * - `quantity`: The quantity of the item being purchased.
 *
 * @example
 * const lineItem: LineItem = {
 *   price: 'price_12345',
 *   quantity: 2,
 * };
 */
export type LineItem = Stripe.Checkout.SessionCreateParams.LineItem;

/**
 * Represents the various states in the lifecycle of a subscription-related operation.
 *
 * ## States
 * - `free`: This status indicates an operation that is not tied to a Stripe invoice or payment.
 *   Free operations are automatically considered "ready" for processing as they do not involve
 *   billing actions (e.g., creating a free project).
 * - `draft`: The operation is in its initial state and does not yet have an associated Stripe invoice.
 * - `pending`: The operation has an associated Stripe invoice, but the invoice has not yet been paid.
 * - `ready`: The Stripe invoice has been successfully paid, and the operation is ready for processing.
 * - `processed`: The backend has started or completed processing the operation, and all associated
 *   actions related to the operation have been or are being executed.
 * - `cancelled`: The operation was created but did not progress to the `ready` or `processed` states.
 *   This can occur if the associated subscription or invoice is canceled.
 *
 * ## Usage
 * This type is used to track and manage the progress of operations within the subscription system.
 * It ensures a clear workflow for both frontend and backend processes by defining the possible states
 * of an operation.
 */
export type OperationStatus =
  | "free"
  | "draft"
  | "pending"
  | "ready"
  | "processed"
  | "cancelled";

/**
 * Represents the status and details of subscription-related operations for an organization.
 *
 * An operation encapsulates the lifecycle of a subscription-related action, such as upgrading a plan,
 * adding an add-on, or creating a free resource. Operations may or may not be tied to Stripe invoices,
 * depending on their type (`free` operations do not require invoices).
 *
 * ## Statuses
 * - `free`: The operation is not related to billing or invoicing (e.g., creating a free project).
 *   Free operations are automatically considered "ready" for processing.
 * - `draft`: The operation is created but does not yet have an associated Stripe invoice.
 * - `pending`: A Stripe invoice exists for the operation, but it has not been paid.
 * - `ready`: The Stripe invoice is paid, and the operation is ready to be processed.
 * - `processed`: The backend has started or completed processing the operation, and all associated
 *   actions have been or are being performed.
 * - `cancelled`: The operation was created but did not progress to the `ready` or `processed` states.
 *   This can happen if the associated subscription or invoice is canceled.
 *
 * ## Structure
 * - `created_at`: The timestamp indicating when the operation was created.
 * - `updated_at`: The timestamp indicating when the operation was last updated.
 * - `invoice_id`: (Optional) The Stripe invoice ID associated with the operation.
 * - `status`: The current status of the operation, reflecting its state in the lifecycle.
 * - `planWithDefaults`: (Optional) Details about the subscription plan involved in the operation.
 * - `addonItems`: (Optional) Details about any add-ons included in the operation.
 */
export type Operation =
  | {
      user_id: string;
      created_at: string;
      updated_at: string;
      invoice_id?: string;
      status: OperationStatus;
      planWithDefaults: PlanWithDefaults;
      addonItems?: never;
    }
  | {
      user_id: string;
      created_at: string;
      updated_at: string;
      invoice_id?: string;
      status: OperationStatus;
      addonItems: AddonItem[];
      planWithDefaults?: never;
    };

/**
 * Represents a collection of subscription-related operations, indexed by their unique operation IDs (`op_id`).
 *
 * Each operation corresponds to a specific action within the subscription system, such as a plan upgrade
 * or the addition of an add-on. The operations progress through defined statuses (`OperationStatus`),
 * providing a clear workflow for handling and processing subscription-related tasks.
 */
export interface Operations {
  [op_id: string]: Operation;
}

/**
 * The props for the `SubscriptionStatusBanner` and `SubscriptionStatusAlertDialog` component.
 *
 * @property {string} title - The title.
 * @property {string} description - The description displayed below the title.
 * @property {string} actionButtonText - The text for the action button on the alert.
 */
export type SubscriptionStatusAlertProps = {
  title: string;
  description: string;
  actionButtonText: string;
};
