//
// getEnvironmentOptions.ts
//

import { Environment } from "@data-types/projects-types";

/**
 * Utility function to get a list of environment options as an iterable array.
 * The function filters the `Environment` object to return only the entries where the value is a number.
 *
 * @returns {Array<{ label: string; value: number }>} An array of objects containing the `label` (string) and `value` (number)
 *   for each valid environment entry in the `Environment` object.
 */
export function getEnvironmentOptions(): {
  label: string;
  value: number;
}[] {
  return Object.entries(Environment)
    .filter(([key, value]) => typeof value === "number")
    .map(([key, value]) => ({
      label: key,
      value: value as number,
    }));
}
