//react
//mui components
import MuiAvatar from "@mui/material/Avatar";

const Avatar = ({ name, img, width = "2rem", height = "2rem" }) => {
  function stringAvatar(name) {
    let children;
    if (name) {
      if (name.split(" ").length > 1) {
        children = `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
      } else {
        children = `${name[0].toUpperCase()}${name[1].toUpperCase()}`;
      }
      return {
        sx: {
          bgcolor: "#F0F5FD",
          color: "#0D0C22",
          fontSize: "1rem",
        },
        children: children,
      };
    }
  }
  return (
    <MuiAvatar
      sx={{ width: width, height: height }}
      alt={name}
      src={img}
      {...stringAvatar(name)}
    />
  );
};

export default Avatar;
