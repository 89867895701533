import { CodeSnippet } from "@tw-components/ui/code-snippet-viewer";

/**
 * Replaces the placeholder `<connection-string>` in the provided code snippets with the actual connection string.
 *
 * @param {Record<string, CodeSnippet>} snippets - The collection of code snippets.
 * @param {string} connectionString - The connection string that will replace the placeholder in the code snippets.
 *
 * @returns {Record<string, CodeSnippet>} A new collection of code snippets with the placeholder replaced by the actual connection string.
 */
export function replaceConnectionString(
  snippets: Record<string, CodeSnippet>,
  connectionString: string
): Record<string, CodeSnippet> {
  const updatedSnippets: Record<string, CodeSnippet> = {};

  for (const key in snippets) {
    const snippet = snippets[key];
    updatedSnippets[key] = {
      ...snippet,
      code: snippet.code.replace(/<connection-string>/g, connectionString),
    };
  }

  return updatedSnippets;
}
