//
// getDatabasesTotalSize.ts
//

import { Databases } from "@data-types/databases-types";

/**
 * Calculates the total size of a list of databases.
 *
 * @param {Databases} databases - An array of database objects, each containing a `size` property.
 *
 * @returns {number | null} The total size of all databases, or `null` if the list is empty or undefined.
 */
export function getDatabasesTotalSize(databases: Databases): number | null {
  if (!databases) return null;

  // Calculate the sum of the `size` property from each database
  return databases.reduce((total, db) => total + db.size, 0);
}
