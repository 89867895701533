import { useGetUserSession } from "@custom-hooks/user";

/**
 * Custom hook to retrieve the ID of the current organization. If no organization exists
 * or the session data is unavailable, it returns `undefined`.
 *
 * @returns {string | undefined} The ID of the current organization, or `undefined` if no organization is found.
 */
export const useGetCurrentOrganizationId = () => {
  const { data: session, hasData: availableSession } = useGetUserSession();

  if (availableSession && session?.authorizations?.organizations) {
    const organizations = session.authorizations.organizations;

    if (Object.keys(organizations).length > 0) {
      return Object.keys(organizations)[0];
    }
  }

  return undefined;
};
