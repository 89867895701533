//
// useCancellSubscription.ts
//

import { useEditData_v2 } from "@custom-hooks/edit-data";
import { useGetUserSession } from "@custom-hooks/user";
import {
  CancelSubscriptionSuccessResponse,
  HandlingCancelSubscriptionFlow,
} from "@data-types/billing-types";
import { EditDataOpt } from "@data-types/generic-hook-type";
import { usePostHog } from "posthog-js/react";
import { useCallback } from "react";
import { useGetCurrentOrganizationId } from "./useGetCurrentOrganizationId";

//TODO:TIZ:Write comment
export const useCancelSubscription = (
  options: EditDataOpt<CancelSubscriptionSuccessResponse>
) => {
  const posthog = usePostHog();
  const { data: session, hasData: availableSession } = useGetUserSession();

  const organizationId = useGetCurrentOrganizationId();

  const projects = availableSession
    ? Object.entries(session?.authorizations?.projects ?? [])
    : [];

  const {
    email = "",
    userId = "",
    projectId = "",
  } = {
    userId: session?.userId,
    email:
      organizationId &&
      session?.authorizations?.organizations[organizationId]?.ownerEmail,
    projectId: projects.length ? projects[0][0] : undefined,
  };

  const { isLoading, isMutatingData, editData } =
    useEditData_v2<CancelSubscriptionSuccessResponse>({
      ...options,
      successCallback: (data) => {
        options.successCallback?.(data);

        const stripeRedirectUrl = data.value.redirectUrl;
        if (stripeRedirectUrl) {
          window.location.href = stripeRedirectUrl;
        } else {
          console.error("Stripe redirect URL is not available.");
        }
      },
      stopGlobalError: options?.stopGlobalError ?? true,
    });

  const cancelSubscription = useCallback(async () => {
    const cancelSubscriptionResponse = await editData({
      method: "DELETE",
      endpoint: `/api/billing/subscriptions/${email}`,
      endpointCallLocation: "useCancelSubscription.js",
      body: {
        successUrl: process.env.NEXT_PUBLIC_BILLING_SUCCESS_URL,
        cancelUrl: process.env.NEXT_PUBLIC_BILLING_CANCEL_URL,
        organizationId,
        email,
        userId,
        projectId,
      } as HandlingCancelSubscriptionFlow,
      mutateApis: [],
    });

    return cancelSubscriptionResponse;
  }, [email, organizationId, projectId, userId, editData]);

  return {
    cancelSubscription,
    isLoading: isLoading || isMutatingData,
  };
};
