//
// useGetUserSession.ts
//

import { useFetchData } from "@custom-hooks/useFetchData";
import { FetchHookResult } from "@data-types/generic-hook-type";
import { Session } from "@data-types/user-types";
import { parseAttributes } from "../utils/parseAttributes";

/**
 * Hook to fetch and manage the user's session data.
 *
 * @param {boolean} [fetchCondition] - A condition to determine if projects should be fetched.
 * @param {number} [refreshInterval] - Optional interval (in milliseconds) for refreshing project storage info data.
 *
 * @returns {FetchHookResult<Session>} The result object with the session
 *   (or `undefined` if no session is available), UI state indicators, and a function to manage fetched data.
 */
export function useGetUserSession(
  fetchCondition?: boolean,
  refreshInterval?: number
): FetchHookResult<Session> {
  const { data, hasData, ...fetchDetails } = useFetchData<any>(
    fetchCondition ?? true,
    `/api/auth/session`,
    "useGetUserSession",
    "auth",
    {
      refreshInterval,
      revalidateOnFocus: false,
    }
  );

  // If session data exists, attempt to parse the `attributes` field as JSON
  const result = hasData ? parseAttributes(data) : undefined;

  // Return all hook states and utilities
  return {
    data: result,
    hasData,
    ...fetchDetails,
  };
}
