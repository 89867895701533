//
// useGetProjectStorageInfo.ts
//

import { useGetFunctionalityDetails } from "@custom-hooks/billing";
import { useGetProjectDatabases } from "@custom-hooks/databases";
import { getDatabasesTotalSize } from "@custom-hooks/databases/utils/getDatabasesTotalSize";
import { PlanFunctionality } from "@data-types/billing-types";
import {
  FetchProjectStorageInfoResult,
  ProjectStorageInfo,
} from "@data-types/projects-types";
import { formatBytes, gigabytesToBytes } from "@lib/iso-utils";

/**
 * Custom hook to fetch and calculate storage usage for a specific project, with a warning threshold.
 *
 * @param {string} projectId - The ID of the project to fetch storage information for.
 * @param {number} [warningThreshold=90] - The percentage threshold at which to trigger a warning (default is 90%).
 * @param {number} [refreshInterval] - Optional interval (in milliseconds) for refreshing project storage info data.
 *
 * @returns {Object} - The result object with the strorage information
 *   (or `undefined` if no strorage information is available), UI state indicators, and a function to manage fetched data.
 */
export function useGetProjectStorageInfo(
  projectId: string,
  warningThreshold: number = 90,
  refreshInterval?: number
): FetchProjectStorageInfoResult<ProjectStorageInfo> {
  const { limit: storageInGigabytes } = useGetFunctionalityDetails(
    PlanFunctionality.STORAGE_IN_GB
  );

  // Fetch project databases data
  const {
    data: databases,
    hasData: availableDatabases,
    isLoading: loadingDatabases,
    isValidating: validatingDatabases,
    isError: errorDatabases,
    showLoader: showLoaderDatabases,
    emptyData: emptyDataDatabases,
    mutate: updatedProjectDatabases,
  } = useGetProjectDatabases(projectId, refreshInterval);

  // Initialize variables for storage info
  let availableStorage,
    usedStorage,
    avaibleStorageInBytes,
    usedStorageInBytes,
    usedStoragePercentage,
    warning;

  // Calculate storage usage if both plan and database data are available
  if (storageInGigabytes && availableDatabases) {
    availableStorage = `${storageInGigabytes} GB`;
    // Convert available storage from GB to bytes
    avaibleStorageInBytes = gigabytesToBytes(storageInGigabytes);

    // Calculate used storage in bytes from database sizes
    usedStorageInBytes = getDatabasesTotalSize(databases!);
    usedStorage = formatBytes(usedStorageInBytes!);

    // Calculate used storage as a percentage
    usedStoragePercentage = (usedStorageInBytes! / avaibleStorageInBytes) * 100;

    // Determine if usage exceeds the warning threshold
    warning = usedStoragePercentage > warningThreshold;

    return {
      data: {
        availableStorage,
        usedStorage,
        avaibleStorageInBytes,
        usedStorageInBytes,
        usedStoragePercentage,
        warning,
      },
      isLoading: false,
      isError: false,
      isValidating: false,
      showLoader: false,
      hasData: true,
      emptyData: false,
    };
  }

  // Return loading and error states, or undefined data if not available
  return {
    data: undefined,
    isLoading: loadingDatabases,
    isError: errorDatabases,
    isValidating: validatingDatabases,
    showLoader: showLoaderDatabases,
    hasData: storageInGigabytes !== undefined && availableDatabases,
    emptyData: storageInGigabytes === undefined || emptyDataDatabases,
    noDatabases: emptyDataDatabases,
    mutate: updatedProjectDatabases,
  };
}
