const useCheckIsTouch = () => {
  let isTouch = false;
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    ) ||
    "ontouchstart" in document.documentElement
  ) {
    isTouch = true;
  } else {
    isTouch = false;
  }
  return isTouch;
};

export { useCheckIsTouch };
