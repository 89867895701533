import { PAST_DUE_SUBSCRIPTIONS_GRACE_PERIOD_DAYS } from "@app/api/billing/lib/constants";
import { useGetUserSession } from "@custom-hooks/user";
import { SubscriptionStatusAlertProps } from "@data-types/billing-types";
import Stripe from "stripe";
import { useGetCurrentOrganizationId } from "./useGetCurrentOrganizationId";

/**
 * Custom hook to retrieve the current subscription status from the user session.
 *
 * @returns {Object} - An object containing the subscription status.
 */
export const useGetSubscriptionStatusDetails = () => {
  const { data: session, hasData: availableSession } = useGetUserSession();
  const organizationId = useGetCurrentOrganizationId();

  const subscriptionStatus =
    availableSession && organizationId
      ? (session!.authorizations?.organizations[organizationId]?.attributes
          ?.subscription?.status as Stripe.Subscription.Status)
      : undefined;

  const statusAlertProps: SubscriptionStatusAlertProps | undefined = (() => {
    switch (subscriptionStatus) {
      case "incomplete":
        return {
          title: "Update Your Payment Method",
          description:
            "Your subscription payment could not be processed. Please update your payment method.",
          actionButtonText: "Edit payment method",
        };
      case "past_due":
        const currentPeriodStart =
          availableSession && organizationId
            ? session?.authorizations?.organizations[organizationId]?.attributes
                ?.subscription?.current_period_start
            : undefined;

        if (currentPeriodStart) {
          const currentPeriodStartDate = new Date(currentPeriodStart * 1000);
          const date = new Date();

          const diffInMs = date.getTime() - currentPeriodStartDate.getTime();
          const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));

          if (PAST_DUE_SUBSCRIPTIONS_GRACE_PERIOD_DAYS > diffInDays) {
            return {
              title: "Update Your Payment Method",
              description: `Your subscription payment is overdue. Please update your payment method to avoid cancellation in ${PAST_DUE_SUBSCRIPTIONS_GRACE_PERIOD_DAYS - diffInDays} day(s).`,
              actionButtonText: "Edit payment method",
            };
          } else {
            // TODO:DAMLA:TIZ - subscription should have been cancelled at this point
          }
        } else {
          return {
            title: "Update Your Payment Method",
            description: `Your subscription payment is overdue. Please update your payment method.`,
            actionButtonText: "Edit payment method",
          };
        }
      case "active":
        if (availableSession && organizationId) {
          const operations =
            session?.authorizations?.organizations[organizationId]?.attributes
              ?.subscription_operations;

          const hasPendingOperations = operations
            ? Object.values(operations).some(
                (operation) => operation.status === "pending"
              )
            : false;

          if (hasPendingOperations) {
            return {
              title: "Pay Your Outstanding Invoice",
              description:
                "Your subscription is active, but some features are restricted due to an unpaid invoice. Complete your payment to restore full functionality.",
              actionButtonText: "Go to invoices",
            };
          }
        }
    }

    return undefined;
  })();

  return {
    subscriptionStatus,
    hasSubscription: subscriptionStatus
      ? ["incomplete", "past_due", "active"].includes(subscriptionStatus)
      : false, // TODO:DAMLA - false or undefined
    subscriptionStatusAlertProps: statusAlertProps,
    canProceedWithOperation: subscriptionStatus
      ? statusAlertProps === undefined
      : false,
  };
};
