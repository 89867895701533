//
// UpgradeDialog.tsx - Upgrade Dialog Component
//

import { plans, PRO_PLAN_MONTHLY_PRICE } from "@app/api/billing/lib/constants";
import {
  useCreateSubscription,
  useGetSubscriptionStatusDetails,
  useViewSubscription,
} from "@custom-hooks/billing";
import { PlanFunctionality, PlanLookupKey } from "@data-types/billing-types";
import BoltIcon from "@layouts/svg-icon/bolt-icon.svg";
import { ContactDialog } from "@tw-components/OrgHome/ContactDialog";
import { GenericDialog } from "@tw-components/ui/dialog";
import { useState } from "react";
import { PlanCard, PlanCardProps } from "./PlanCard";

/**
 * The props for the UpgradeDialog.
 *
 * @property {boolean} isOpen - Indicates whether the dialog is open.
 * @property {() => void} onClose - Callback function to close the dialog.
 */
type UpgradeDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  createProjectToUpgrade?: () => void;
};

/**
 * Component for displaying
 *
 * @property {boolean} isOpen - Indicates whether the dialog is open.
 * @property {() => void} onClose - Callback function to close the dialog.
 *
 * @returns {JSX.Element} The rendered `UpgradeDialog` component.
 */
export function UpgradeDialog({
  isOpen,
  onClose,
  createProjectToUpgrade,
}: UpgradeDialogProps): JSX.Element {
  const rowNames = [
    PlanFunctionality.CLUSTER,
    PlanFunctionality.STORAGE_IN_GB,
    `${PlanFunctionality.VCPU} / ${PlanFunctionality.RAM}`,
    PlanFunctionality.RETENTION_IN_HOURS,
    PlanFunctionality.SUPPORT,
    "Cost",
  ];

  const currentPlanDetails = plans[PlanLookupKey.SANDBOX].functionalityDetails;
  const proPlanDetails =
    plans[PlanLookupKey.PRO_PLAN_MONTHLY].functionalityDetails;

  const currentPlanCardProps: PlanCardProps = {
    title: "Current Plan",
    details: [
      { ...currentPlanDetails.Cluster },
      { ...currentPlanDetails.Storage },
      {
        summary: `${currentPlanDetails.vCPUs?.summary} / ${currentPlanDetails.RAM?.summary}`,
      },
      { summary: "None" },
      { ...currentPlanDetails.Support },
      { summary: "Free" },
    ],
  };

  const proPlanCardProps: PlanCardProps = {
    title: "Pro",
    details: [
      { ...proPlanDetails.Cluster },
      { ...proPlanDetails.Storage },
      {
        summary: `${proPlanDetails.vCPUs?.summary} / ${proPlanDetails.RAM?.summary}`,
      },
      { ...proPlanDetails.Backups },
      { ...proPlanDetails.Support },
      { summary: `$${PRO_PLAN_MONTHLY_PRICE} / month / project` },
    ],
  };

  const [showContactDialog, setShowContactDialog] = useState(false);

  const { subscriptionStatus } = useGetSubscriptionStatusDetails();
  const { createSubscription, isLoading: isLoadingCreateSubscription } =
    useCreateSubscription({});
  const { viewSubscription, isLoading: isLoadingViewSubscription } =
    useViewSubscription({});

  // TODO:DAMLA - handle create and view errors

  const handleUpgrade = async () => {
    if (createProjectToUpgrade) {
      createProjectToUpgrade();
    } else {
      if (subscriptionStatus === "incomplete") {
        await viewSubscription();
      } else {
        await createSubscription(PlanLookupKey.PRO_PLAN_MONTHLY);
      }
    }
  };

  return (
    <>
      <GenericDialog
        title="Upgrade to SQLite Cloud Pro"
        description={
          createProjectToUpgrade
            ? "Please create a free project to continue."
            : "Upgrading to SQLite Cloud Pro will automatically turn your existing free project into a pro project."
        }
        body={
          <div className="tw-flex tw-flex-row">
            <div className="tw-flex tw-flex-col tw-justify-end tw-gap-5 tw-pb-4 tw-pr-2 sm:tw-min-w-28 sm:tw-pr-8">
              {rowNames.map((rowName) => (
                <div
                  key={rowName}
                  className="tw-text-12px-semiBold tw-text-text-subTitle-light dark:tw-text-text-subTitle-dark"
                >
                  {rowName}
                </div>
              ))}
            </div>

            <div className="tw-grid tw-w-full tw-grid-cols-2 tw-gap-4">
              <PlanCard {...currentPlanCardProps} />
              <PlanCard
                {...proPlanCardProps}
                classname="tw-bg-interface-innerCard-light dark:tw-bg-interface-innerCard-dark"
              />
            </div>
          </div>
        }
        secondaryButtonProps={{
          text: "Need more? Contact us",
          onClick: () => {
            onClose();
            setShowContactDialog(true);
          },
        }}
        submitButtonProps={{
          variant: "primary",
          label: createProjectToUpgrade
            ? "Create free project"
            : "Upgrade to Pro",
          icon: <BoltIcon />,
          onClick: handleUpgrade,
          className: "tw-w-[22.188rem]",
          showLoader: isLoadingCreateSubscription || isLoadingViewSubscription,
        }}
        onClose={onClose}
        open={isOpen}
        size="xl"
        buttonLayoutType="column"
      />

      <ContactDialog
        isOpen={showContactDialog}
        onClose={() => setShowContactDialog(false)}
      />
    </>
  );
}
