import { useGetUserSession } from "@custom-hooks/user";
import { PlanLookupKey } from "@data-types/billing-types";
import { useGetCurrentOrganizationId } from "./useGetCurrentOrganizationId";

/**
 * Custom hook to determine the user's subscription plan based on their session data.
 *
 * @return the user's subscription plan. Defaults to `SANDBOX` if unavailable.
 */
export const useGetUserPlan = () => {
  const { data: session, hasData: availableSession } = useGetUserSession();
  const organizationId = useGetCurrentOrganizationId();

  return availableSession && organizationId
    ? ((session!.authorizations?.organizations[organizationId]?.attributes
        ?.subscription_plan as PlanLookupKey) ?? PlanLookupKey.SANDBOX)
    : undefined;
};
