//
// available-language-types.ts
//

import GoSvg from "@layouts/svg-icon/go.svg";
import JavaScriptSvg from "@layouts/svg-icon/javascript.svg";
import PhpSvg from "@layouts/svg-icon/php.svg";
import PythonSvg from "@layouts/svg-icon/python.svg";
import ReactNativeSvg from "@layouts/svg-icon/react-native.svg";
import ReactSvg from "@layouts/svg-icon/react.svg";
import SwiftSvg from "@layouts/svg-icon/swift.svg";

export enum AvailableLanguageNames {
  JAVASCRIPT = "JavaScript",
  REACT = "React",
  REACT_NATIVE = "React Native",
  SWIFT = "Swift",
  PYTHON = "Python",
  GO = "Go",
  PHP = "PHP",
}

export interface AvailableLanguage {
  name: string;
  code: string;
  icon: any;
}

export const JavaScript: AvailableLanguage = {
  name: AvailableLanguageNames.JAVASCRIPT,
  code: "js",
  icon: JavaScriptSvg,
};

export const ReactNative: AvailableLanguage = {
  name: AvailableLanguageNames.REACT_NATIVE,
  code: "ts",
  icon: ReactNativeSvg,
};

export const Swift: AvailableLanguage = {
  name: AvailableLanguageNames.SWIFT,
  code: "swift",
  icon: SwiftSvg,
};

export const React: AvailableLanguage = {
  name: AvailableLanguageNames.REACT,
  code: "ts",
  icon: ReactSvg,
};

export const Python: AvailableLanguage = {
  name: AvailableLanguageNames.PYTHON,
  code: "python",
  icon: PythonSvg,
};

export const Go: AvailableLanguage = {
  name: AvailableLanguageNames.GO,
  code: "go",
  icon: GoSvg,
};

export const Php: AvailableLanguage = {
  name: AvailableLanguageNames.PHP,
  code: "php",
  icon: PhpSvg,
};
