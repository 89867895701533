import { useGetUserSession } from "@custom-hooks/user";
import { useGetCurrentOrganizationId } from "./useGetCurrentOrganizationId";

export const useGetUserHasCancelledSubscription = () => {
  const { data: session, hasData: availableSession } = useGetUserSession();
  const organizationId = useGetCurrentOrganizationId();

  return availableSession && organizationId
    ? session!.authorizations?.organizations[organizationId]?.attributes
        ?.subscription_cancelled_at
    : false;
};
