//
// progress-bar.tsx
//

import ProgressBarLoaderMask from "@layouts/svg-illustration/progressbar-loader-mask.svg";
import clsx from "clsx";

// ProgressBar types
type VariantsProgressBar = "base" | "error";

type StyleItemProgressBar = string[];

type VariantStyleProgressBar = {
  [K in VariantsProgressBar]: {
    colors: StyleItemProgressBar;
  };
};

// ProgressBar styles
const variantStyleProgressBar: VariantStyleProgressBar = {
  base: {
    colors: [
      // Background color light
      "tw-bg-brandBlues-brand-light",
      // Background color dark
      "tw-dark:tw-bg-brandBlues-brand-dark",
    ],
  },
  error: {
    colors: [
      // Background color light
      "tw-bg-semantics-error-light",
      // Background color dark
      "tw-dark:tw-bg-semantics-error-dark",
    ],
  },
};

// ProgressBar Props
export type ProgressBarProps = {
  value: string | number;
  type?: "indeterminate" | "determinate";
  variant?: VariantsProgressBar;
};

export function ProgressBar({
  value,
  variant = "base",
  type = "determinate",
}: ProgressBarProps) {
  return (
    <div aria-hidden="true">
      <div className="tw-dark:tw-bg-brandBlues-ice-light tw-relative tw-overflow-hidden tw-rounded tw-bg-brandBlues-ice-light">
        {type === "indeterminate" && (
          <div className="tw-absolute tw-inset-0">
            <ProgressBarLoaderMask className="tw-animate-progressBar1 tw-absolute tw-inset-0 tw-transform tw-transition-transform tw-duration-[3000ms] tw-ease-in-out" />
            <ProgressBarLoaderMask className="tw-animate-progressBar2 tw-absolute tw-inset-0 -tw-translate-x-full tw-transform tw-transition-transform tw-duration-[3000ms]  tw-ease-in-out" />
          </div>
        )}
        <div
          style={{ width: type === "determinate" ? `${value}%` : "0" }}
          className={clsx(
            "tw-h-[0.625rem] tw-rounded",
            "tw-transition-all tw-duration-1000 tw-ease-out",
            // Colors
            variantStyleProgressBar[variant].colors
          )}
        />
      </div>
    </div>
  );
}
