//
// useGetProjectInfo.ts
//

import { useFetchData } from "@custom-hooks/useFetchData";
import { FetchHookResult } from "@data-types/generic-hook-type";
import { Project, RawProject } from "@data-types/projects-types";
import { useMemo } from "react";

/**
 * Custom hook to fetch and analyze information for a specific project by its ID.
 *
 * @param {string} projectId - The ID of the project to fetch data for.
 * @param {number} [refreshInterval] - Optional interval (in milliseconds) for refreshing project info data.
 *
 * @returns {FetchHookResult<Project>} The result object containing the parsed project data, including a
 *   `regionsList` extracted from the project's `regions` JSON field (or `undefined` if no project data is available),
 *   along with UI state indicators and a function to manage fetched data.
 */
export function useGetProjectInfo(
  projectId: string,
  refreshInterval?: number
): FetchHookResult<Project> {
  const { data, ...fetchDetails } = useFetchData<RawProject>(
    projectId !== "",
    `/api/projects/${projectId}`,
    "useGetProjectInfo",
    "backend",
    {
      refreshInterval,
      revalidateOnFocus: false,
    }
  );

  const parsedData: Project | undefined = useMemo(() => {
    if (data) {
      try {
        const regionsList = JSON.parse(data.regions);
        return {
          ...data,
          regionsList: regionsList[0] ? regionsList : [],
        };
      } catch (error) {
        return {
          ...data,
          regionsList: [],
        };
      }
    }
    return undefined;
  }, [data]);

  // Return hook's state and data indicators, including nodes_count if available
  return {
    data: parsedData,
    ...fetchDetails,
  };
}
