//
// projects-types.ts
//

import { FetchHookResult } from "./generic-hook-type";
import { Regions } from "./regions-types";

export enum Environment {
  Development = 0,
  Staging = 1,
  Production = 2,
}

export type EnvironmentInfo = {
  color: string;
  label: "DEVELOPMENT" | "STAGING" | "PRODUCTION";
};

export type RawProject = {
  admin_password: string;
  admin_username: string;
  creation_date: string;
  description: string;
  env: Environment;
  id: string;
  name: string;
  nodes_count: number;
  creating_count: number;
  project_address: string;
  regions: string; // e.g. [{"code":"us-west-1","description":"US West (N. California)"}]
};

export type Project = Omit<RawProject, "regions"> & {
  regionsList: Regions;
};

export type RawProjects = RawProject[];

export type Projects = Project[];

export type ProjectStorageInfo = {
  availableStorage: string;
  usedStorage: string;
  avaibleStorageInBytes: number;
  usedStorageInBytes: number | null;
  usedStoragePercentage: number;
  warning: boolean;
};

/**
 * Extends FetchHookResult to include specific flags for tracking job progress.
 *
 * @template T - The type of data being fetched, such Jobs.
 * @property {boolean} isAnyJobInProgress - Indicates if at least one job is currently in progress.
 */
export type FetchProjectJobsResult<T> = FetchHookResult<T> & {
  isAnyJobInProgress: boolean;
};

/**
 * Extends the `FetchHookResult` type to include additional flags for tracking job progress and storage info.
 * This type adds support for managing the presence of databases and an optional mutate function.
 *
 * @template T - The type of data being fetched, which extends `FetchHookResult<T>`.
 *
 * @property {boolean} [noDatabases] - A flag indicating whether no databases were found.
 * @property {function} [mutate] - A function to manually refresh or update the data.
 */
export type FetchProjectStorageInfoResult<T> = Omit<
  FetchHookResult<T>,
  "mutate"
> & {
  noDatabases?: boolean;
  mutate?: () => void;
};
