//
// parseAttributes.ts
//

import { safelyParseJSON } from "@lib/iso-utils";

/**
 * Recursively parses the "attributes" field in an object, attempting to safely parse JSON strings.
 * If parsing fails, the original string is preserved.
 *
 * - If a key is named "attributes" and is a string, it tries to parse it as JSON.
 * - For nested objects, it recursively applies the same logic.
 *
 * @param obj - The input object whose attributes need parsing.
 *
 * @returns The object with parsed attributes wherever applicable.
 */
export function parseAttributes(obj: any): any {
  // Iterate through each key in the object
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (key === "attributes" && typeof obj[key] === "string") {
        // Parse the attributes string if it's a valid JSON
        obj[key] = safelyParseJSON(obj[key]);
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        // Recursively check nested objects
        obj[key] = parseAttributes(obj[key]);
      }
    }
  }

  return obj;
}
